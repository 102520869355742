<template>
  <div>
    <div
      class="home"
      style="position: relative;height: calc(100% - 150px);min-width:1024px;overflow:auto;"
    >
      <Top></Top>
      <div class="login_btn">
        <img src="../assets/logo_btn.png" alt />
      </div>
      <div class="logo_buttom">
        <img src="../assets/logo_buttom.png" alt />
      </div>
      <div
        style="
         width: 400px;
        background: #fff;
        padding: 42px;
        top: 140px;
        right: 440px;
        position: absolute;
        "
      >
        <div style=" font-size: 18px;display: flex;justify-content: center;">
          <img src="../assets/logo_title.png" alt />
        </div>
        <div style="margin:15px 0;display: flex;justify-content: center;">
          <img src="../assets/value.png" v-prev path="/login" alt />
          <img src="../assets/value_show.png" style="margin-left:5px" alt />
        </div>
        <img src="../assets/Hi.png" alt />
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <span>欢迎注册放薪管家！</span>

          <div>
            <span>已有账号了？</span>
            <el-button type="text" v-prev path="/login">返回登录</el-button>
          </div>
        </div>
        <!-- <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane name="second" v-preAccess data-ctrl="app.enterpriseRegister">-->
        <span slot="label">企业注册</span>
        <div style="margin: 10px 0">
          <el-form
            :model="enterpriseForm"
            status-icon
            :rules="enterpriseRules"
            ref="enterpriseForm"
            class="demo-userForm"
          >
            <el-form-item prop="enterpriseName">
              <el-input
                type="text"
                placeholder="请输入企业名称"
                v-model="enterpriseForm.enterpriseName"
                clearable
                auto-complete="off"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item prop="username">
              <el-input
                type="text"
                placeholder="请输入手机号"
                v-model="enterpriseForm.username"
                maxlength="11"
                clearable
                auto-complete="off"
                autocomplete="offf"
              ></el-input>
            </el-form-item>

            <el-form-item prop="password">
              <el-input
                type="password"
                placeholder="请输入密码"
                v-model="enterpriseForm.password"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
              <el-input
                type="password"
                placeholder="请再次输入密码"
                v-model="enterpriseForm.checkPass"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item prop="smsCode">
              <el-input
                type="number"
                placeholder="请输入验证码"
                v-model="enterpriseForm.smsCode"
                clearable
                auto-complete="off"
                autocomplete="off"
              >
                <template slot="append">
                  <div
                    style="cursor: pointer"
                    @click="getSmsCode('E')"
                    v-show="!isShowESconds"
                  >获取验证码</div>
                  <div v-show="isShowESconds" style="color: #409eff">{{ eSeconds }}S后重新获取</div>
                </template>
              </el-input>
            </el-form-item>
            <div style="margin-bottom: 20px; text-align: left">
              <el-checkbox v-model="enterpriseIdenAgree" @change="eAgreeChange">我同意</el-checkbox>
              <span style="color:#409EFF;font-size:14px;" @click="dialogVisible = true">《企业注册协议》</span>
            </div>
            <div>
              <el-button
                style="width: 100%"
                type="primary"
                @click="submitForm('enterpriseForm', 2)"
              >注册</el-button>
            </div>
          </el-form>
        </div>
        <!-- </el-tab-pane>
        </el-tabs>-->
        <div style="text-align: right"></div>
      </div>
    </div>
    <el-dialog title="《用户注册协议》- 企业端" :visible.sync="dialogVisible" width="1000px" top="0">
      <iframe
        id="viewIframe"
        width="100%"
        style="height: calc( 100vh - 145px);"
        :src="src"
        frameborder="0"
      ></iframe>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goOn">同意并继续</el-button>
      </span>
    </el-dialog>
    <Footer></Footer>
  </div>
</template>
<script>
import Top from '../components/top.vue'
import Footer from '../components/footer.vue'
// import config from '../config/config';
import { api } from '/src/api/base';
export const userExsit = api()('user.exsit.json');
import { userSendRegistryCode, userRegistry, enterpriseRegistry } from '../api';
export default {
  components: {
    [Top.name]: Top,
    [Footer.name]: Footer
  },
  data () {
    var validatePhone = async (rule, value, callback) => {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!phoneReg.test(value)) {
        callback(new Error('请输入正确手机号'));
      } else if (phoneReg.test(value)) {
        await this.goTel(this.enterpriseForm.username);

        if (this.isSign) {
          callback(new Error('当前手机号已注册'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkSmsCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入验证码'));
      }
      setTimeout(() => {
        if (value.length !== 6) {
          callback(new Error('请输入6位验证码'));
        } else {
          callback();
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      const reg = /^[\w_-]{6,16}$/;
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!reg.test(value)) {
        callback(new Error('密码必须为6-16位数字或者字母组成'));
      } else {
        if (this.userForm.checkPass !== '') {
          this.$refs.userForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.userForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.enterpriseForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var validateEnterpriseName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入企业名称'));
      } else {
        callback();
      }
    };
    var validateInvitCdoe = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入邀请码'));
      } else {
        callback();
      }
    };
    return {
      pattern: /^.*(?=.{6,16})(?=.*\d)(?=.*[A-Z]{2,})(?=.*[a-z]{2,})(?=.*[!@#$%^&*?()]).*$/,
      dialogVisible: true,
      invitation: '',

      activeName: 'second',
      invitationLock: false,
      input: '',
      isSign: true,
      userForm: {
        username: '',
        smsCode: '',
        password: '',
        checkPass: '',
        invitCdoe: '',
      },
      userRules: {
        username: [{ validator: validatePhone, trigger: 'change' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
        smsCode: [{ validator: checkSmsCode, trigger: 'blur' }],
        invitCdoe: [{ validator: validateInvitCdoe, trigger: 'blur' }],
      },
      enterpriseForm: {
        enterpriseName: '',
        username: '',
        smsCode: '',
        password: '',
        checkPass: '',
        invitCode: '',
      },
      enterpriseRules: {
        enterpriseName: [
          { validator: validateEnterpriseName, trigger: 'blur' },
        ],
        username: [{ validator: validatePhone, trigger: 'change' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass3, trigger: 'blur' }],
        smsCode: [{ validator: checkSmsCode, trigger: 'blur' }],
        invitCode: [{ validator: validateInvitCdoe, trigger: 'blur' }],
      },
      seconds: 60,
      isShowSconds: false,
      idenAgree: false,
      isShowESconds: false,
      eSeconds: 60,
      enterpriseIdenAgree: false,
      src: window.location.origin + '/content/knppapvx0/document.html',
      config: {},
    };
  },
  created () {
    this.$route.params.$preload.customSetting.forEach((it) => {
      this.config[it.name] = it.value;
    });
  },
  mounted () {
    this.enterpriseForm.invitCode = this.$route.query.invitation;
    console.log(this.enterpriseForm.invitCode);
    if (this.$route.query.invitation) {
      this.invitationLock = true;
      this.invitation = this.$route.query.invitation;
      if (this.invitation) {
        window.localStorage.setItem('invitation', this.invitation);
      } else {
        this.invitation = window.localStorage.getItem('invitation');
      }
    }
  },
  methods: {
    async goTel (username) {
      const res = await userExsit({
        username: username,
      });
      this.isSign = res.exist ? true : false;
    },
    goOn () {
      this.dialogVisible = false;
      this.enterpriseIdenAgree = true;
    },
    handleClick (tab, event) {
      console.log(tab, event);
    },
    async getSmsCode (type) {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

      if (!this.isSign) {
        if (type === 'U') {
          if (!this.userForm.username) {
            return this.$message.error('请输入手机号码');
          }
          if (
            this.userForm.username &&
            !phoneReg.test(this.userForm.username)
          ) {
            return this.$message.error('请输入正确的手机号码');
          }

          await userSendRegistryCode({
            phone: this.userForm.username,
          });
          this.$message.success('验证码发送成功');
          this.isShowSconds = true;
          const _interval1 = setInterval(() => {
            this.seconds--;
            if (this.seconds === 0) {
              clearInterval(_interval1);
              this.isShowSconds = false;
              this.seconds = 59;
            }
          }, 1000);
        }
        if (type === 'E') {
          if (!this.enterpriseForm.username) {
            return this.$message.error('请输入手机号码');
          }
          if (
            this.enterpriseForm.username &&
            !phoneReg.test(this.enterpriseForm.username)
          ) {
            this.$message.error('请输入正确的手机号码');
            this.isShowESconds = false;
          }

          await userSendRegistryCode({
            phone: this.enterpriseForm.username,
          });
          this.$message.success('验证码发送成功');
          this.isShowESconds = true;
          const _interval2 = setInterval(() => {
            this.eSeconds--;
            if (this.eSeconds === 0) {
              clearInterval(_interval2);
              this.isShowESconds = false;
              this.eSeconds = 59;
            }
          }, 1000);
        }
      }
    },
    agreeChange (val) {
      this.idenAgree = val;
    },
    eAgreeChange (val) {
      this.enterpriseIdenAgree = val;
    },
    submitForm (formName, type) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (type === 1) {
            if (!this.idenAgree) {
              return this.$message.error('用户必须同意用户注册协议！');
            }
            await userRegistry({
              username: this.userForm.username,
              password: this.userForm.password,
              smsCode: this.userForm.smsCode,
              invitation: this.$route.params.iv,
            });
          }
          if (type === 2) {
            if (!this.enterpriseIdenAgree) {
              return this.$message.error('企业必须同意企业注册协议！');
            }
            await enterpriseRegistry({
              enterpriseName: this.enterpriseForm.enterpriseName,
              username: this.enterpriseForm.username,
              password: this.enterpriseForm.password,
              smsCode: this.enterpriseForm.smsCode,
              invitation: this.$route.query.invitation,
            });
          }
          this.$message.success('注册成功');
          this.$router.push({
            path: '/login',
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.el-divider.line {
  background-color: #fff;
}
</style>
<style lang="scss" scoped>
.el-tabs__nav {
  display: flex;
  width: 100%;
}
.el-tabs__item {
  flex-grow: 1;
}
.el-tabs__nav > .el-tabs__item:last-child {
  border-right: none;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 25px;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
.el-dialog__body {
  max-height: 500px;
  overflow: auto;
  padding: 10px 40px;
}
.el-form-item__content {
  margin-left: 0 !important;
}
.el-tabs--border-card {
  border: 1px solid #dcdfe6;
}
.top-left {
  width: 136px;
  height: 30px;

  img {
    width: 100%;
    height: 100%;
  }
}
.top-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #131824;
  width: 50%;
}
.login_btn {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}
.logo_buttom {
  width: 100%;
  display: flex;
  align-items: unset;
  justify-content: center;
  margin: 100px 0;
  img {
    width: 1300px;
  }
}
</style>
